<template>
  <div class="wrap" v-loading="loading">
    <!-- 顶部按钮 -->
    <topOperatingButton
      :isSubmitAddBtn="false"
      :isAuditBillBtn="false"
      @submitForm="submitForm"
      @getQuit="getQuit"
    >
    </topOperatingButton>
    <el-form
      ref="form"
      :model="posImageForm"
      :rules="rules"
      label-width="100px"
    >
      <cardTitleCom cardTitle="基本信息">
        <template slot="cardContent">
          <div class="tableContent">
            <div class="x-f">
              <el-form-item label="方案编号" prop="posImageNo">
                <el-input
                  disabled
                  class="inputWidth"
                  v-model="posImageForm.posImageNo"
                  placeholder="方案编号"
                ></el-input>
              </el-form-item>
              <el-form-item label="方案名称" prop="posImageName">
                <el-input
                  class="inputWidth"
                  v-model="posImageForm.posImageName"
                  placeholder="方案名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注">
                <el-input
                  class="inputRemark"
                  v-model="posImageForm.posImageRemark"
                  type="textarea"
                  placeholder="备注长度介于 1 和 80 字符之间"
                  maxlength="80"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                />
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="图片管理">
        <template slot="cardContent">
          <div class="tableContentImg y-start">
            <span class="fontS14 marB20">PC POS主屏logo</span>
            <accessory-upload
              class="marB20"
              listType="picture-card"
              :limit="1"
              title=""
              :fileList="fileListLogo"
              @getFileItems="getFileItemsDataLogo"
              @delFileItems="delFileItemsLogo"
              :fileSize="1"
            />
            <span class="fontS14 marB20">PC POS副屏推广轮播图</span>
            <accessory-upload
              class="marB20"
              listType="picture-card"
              :limit="10"
              title=""
              :fileList="fileListCarousel"
              @getFileItems="getFileItemsData"
              @delFileItems="delFileItems"
              :fileSize="1"
            />
            <div class="x-f marB20">
              <span class="fontS14 marR20">轮播间隔时长</span>
              <el-input
                v-model="posImageForm.carouselSecond"
                class="inputWidth marR10"
                type="number"
              />
              <span class="fontS14">秒</span>
            </div>
            <div class="marB10 x-f">
              <span class="fontS14 marR10">购物车占屏幕的百分比</span>
              <span class="fontS14 annotateGrey marR20"
                >（不选，默认值为40%）</span
              >
              <el-select
                class="inputRemark"
                v-model="posImageForm.carouselSizeType"
                placeholder="全部"
                filterable
                clearable
                size="mini"
              >
                <el-option
                  v-for="item in resolutionList"
                  :key="item.resolutionId"
                  :label="item.resolutionName"
                  :value="item.resolutionId"
                />
              </el-select>
            </div>
            <div class="fontS14 annotateGrey">
              推荐： 轮播图高度：屏幕分辨率的高度；轮播图宽度：屏幕分辨率的宽度
              - 屏幕分辨率的宽度 * 购物车占屏幕的百分比
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="适用门店">
        <template slot="cardContent">
          <div class="tableContent">
            <!-- 实用门店 -->
            <div>
              <el-form-item label="适用门店" prop="shopRangeType">
                <el-radio-group v-model="posImageForm.shopRangeType">
                  <el-radio
                    v-for="dict in dict.type.fill_shop_range_type"
                    :key="dict.value"
                    :label="parseInt(dict.value)"
                    >{{ dict.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <!-- 非全部门店 -->
              <div
                class="marL20"
                v-show="
                  posImageForm.shopRangeType == 1 ||
                  posImageForm.shopRangeType == 2
                "
              >
                <el-button
                  size="mini"
                  class="marB10"
                  @click="openShopInfo = true"
                  >选择门店</el-button
                >
                <el-table
                  style="width: 100%"
                  tooltip-effect="dark"
                  :data="posImageForm.shopIds"
                  border
                  max-height="500"
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    prop="userId"
                    width="80"
                    type="index"
                  />
                  <el-table-column align="center" label="操作" width="120">
                    <template v-slot="scope">
                      <el-button
                        @click.native.prevent="
                          deleteRow(scope.$index, posImageForm.shopIds)
                        "
                        type="text"
                        size="mini"
                      >
                        <i class="el-icon-remove operateDel" />
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="门店编号"
                    width="200"
                    align="center"
                    prop="shopNo"
                  />
                  <el-table-column
                    label="门店名称"
                    align="center"
                    width="200"
                    prop="shopName"
                  />
                  <el-table-column />
                </el-table>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 门店 -->
    <shopInfo
      :OpenShopInfo.sync="openShopInfo"
      @shopInfoList="shopInfoListCom"
    />
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import AccessoryUpload from "@/views/components/accessoryUpload/index.vue";
import shopInfo from "@/views/components/shopInfo"; //门店
import {
  posImageAddAPI,
  posImageDetailAPI,
  posImageUpdateAPI,
} from "@/api/shop/setup/posImage";
import { getBillNo } from "@/api/codeRule"; //  单据编号
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
export default {
  name: "posImageDetail",
  dicts: ["fill_shop_range_type"],
  components: {
    cardTitleCom,
    AccessoryUpload,
    shopInfo,
    topOperatingButton,
  },
  data() {
    return {
      //Logo回显
      fileListLogo: [],
      //轮播回显
      fileListCarousel: [],
      //门店弹窗开关
      openShopInfo: false,
      //分辨率
      resolutionList: [
        {
          resolutionName: "15%",
          resolutionId: 1,
        },
        {
          resolutionName: "20%",
          resolutionId: 2,
        },
        {
          resolutionName: "30%",
          resolutionId: 3,
        },
      ],
      //大盒子遮罩层
      loading: false,
      //旧的表单
      oldForm: {},
      //plus会员方案表单
      posImageForm: {
        shopRangeType: 0,
        shopIds: [],
        carouselUrls: [],
        carouselSecond: 5,
        carouselSizeType: 3,
        posImageNo: "111",
      },
      //plus会员方案表单校验
      rules: {
        posImageNo: [
          {
            required: true,
            message: "请到编码规则配置单据类型",
            trigger: ["blur", "change"],
          },
        ],
        posImageName: [
          {
            required: true,
            message: "方案名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 重置数据
      vm.reset();
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        if (vm.$route.query.posImageId) {
          //获取单据详情
          const { data } = await posImageDetailAPI(vm.$route.query.posImageId);
          //单据赋值
          vm.posImageForm = data;
          //Logo回显
          vm.fileListLogo = vm.posImageForm.posLogoUrl
            ? [
                {
                  name: "Logo",
                  url: vm.posImageForm.posLogoUrl,
                },
              ]
            : [];
          //轮播
          vm.fileListCarousel =
            vm.posImageForm.carouselUrls.length > 0
              ? vm.posImageForm.carouselUrls.map((item) => ({
                  name: item.attachFileName,
                  url: item.attachFileUrl,
                }))
              : [];

          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.posImageForm));
        }
      } else {
        console.log("新增图片方案-----");
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.posImageForm = vm.oldForm;
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.posImageForm));
        vm.getOrderBillNo();
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.posImageForm.posImageId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.posImageForm));
    }
    next();
  },
  methods: {
    async getOrderBillNo() {
      const res = await getBillNo(140101);
      this.$set(this.posImageForm, "posImageNo", res);
    },
    /** 提交按钮 */
    async submitForm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let isUpdate = !!this.posImageForm.posImageId;
          let oldShopIds = JSON.parse(
            JSON.stringify(this.posImageForm.shopIds)
          );
          try {
            if (
              this.posImageForm.shopIds &&
              this.posImageForm.shopIds.length > 0
            ) {
              this.posImageForm.shopIds = this.posImageForm.shopIds.map(
                (item) => item.shopId
              );
            }
            const apiMethod = isUpdate ? posImageUpdateAPI : posImageAddAPI;
            const res = await apiMethod(this.posImageForm);
            // 重新赋值给表单并提示
            let msg = isUpdate ? "修改方案成功" : "新增方案成功";
            this.posImageForm = res.data;
            this.$modal.msgSuccess(msg);
          } catch (error) {
            this.posImageForm.shopIds = oldShopIds;
          }
        }
      });
    },
    //门店确定
    shopInfoListCom(value) {
      if (value.length == 0) return;
      //去重
      value = value.filter((item) => {
        return this.posImageForm.shopIds.every((v) => v.shopId != item.shopId);
      });
      if (value.length == 0) return;
      this.posImageForm.shopIds.push(...value);
      this.posImageForm.shopIds = this.posImageForm.shopIds.filter(
        (item) =>
          (!(JSON.stringify(item) === "{}") && !(item.shopId === undefined)) ||
          !(item.shopId === undefined)
      );
    },
    //上传阿里图库  轮播
    getFileItemsData(value) {
      this.posImageForm.carouselUrls.push({
        attachFileName: value.fileName,
        attachFileUrl: value.accessUrl,
        fileSize: value.fileSize,
      });
    },
    //删除阿里图库
    delFileItems(value) {
      this.posImageForm.carouselUrls.forEach((item, index) => {
        if (item.attachFileUrl == value.url) {
          console.log("进来");
          this.posImageForm.carouselUrls.splice(index, 1);
        }
      });
    },
    //上传阿里图库 Logo
    getFileItemsDataLogo(value) {
      this.posImageForm.posLogoUrl = value.accessUrl;
    },
    //删除阿里图库 Logo
    delFileItemsLogo(value) {
      this.posImageForm.posLogoUrl = undefined;
    },

    // 表单重置
    reset() {
      //回显Logo
      this.fileListLogo = [];
      this.posImageForm = {
        shopRangeType: 0,
        shopIds: [],
        carouselUrls: [],
        carouselSecond: 5,
        carouselSizeType: 3,
      };
      this.resetForm("form");
    },
    //删除门店内容
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },

    //退出
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          (item) => item.path === this.$route.path
        ),
        1
      );
      this.$destroy();
      this.$router.push("/shop/setup/posImage");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  //内容
  .tableContent {
    width: 100%;
    padding: 6px 10px 15px 10px;
  }
  //图片管理
  .tableContentImg {
    padding: 20px 0 10px 30px;
  }
  //输入框
  ::v-deep .el-input--medium .el-input__inner {
    height: 28px;
    line-height: 28px;
  }
  ::v-deep .el-form-item {
    margin: 0;
    padding: 0;
  }
  //输入框原生属性
  /**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
  ::v-deep .el-input__inner {
    line-height: 1px !important;
  }

  /**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
  ::v-deep input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
